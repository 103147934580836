@import "_variables.scss";
.platform-setup{
.resource-title {
  font-weight: bold;
  font-size: var(--chakra-fontSizes-2xl);
}
.app-card-container {    
   max-width: 855px;
}
}

.resource-description {
  font-size: var(--chakra-fontSizes-2xl);
  margin-right: var(--chakra-space-20);
  margin-top:var(--chakra-space-1);
}
.plat-form-continer {
  margin: var(--chakra-space-10);
}
.resource-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--chakra-space-4);
}
.bottom-divider {
  border: 1px solid $color-border;
  margin: var(--chakra-space-10) 0px var(--chakra-space-10) 0px;
}
.academy-link {
  font-weight: bold;
  font-size: var(--chakra-fontSizes-2xl);
  .academy-link-arrow {
    font-size: var(--chakra-fontSizes-3xl);
  }
}

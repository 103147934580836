@import "_variables.scss";
@import "mixins.scss";

.api-detail {
  height: 100%;
  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: var(--chakra-space-1);
    margin-left: 20% !important;
    gap: var(--chakra-space-12-8);
  }
  .grid {
    padding: 0;
  }
  .cw,
  .cs {
    .cw-card,
    .cs-card {
      @include card-container();
    }
  }
}

@import "_variables.scss";
.tag-style {
  background-color: $color-bg-badge;
  color: $color-font-card;
  text-transform: uppercase;
  width: 16rem;
  cursor: default;
  border-radius: var(--chakra-space-10);
  margin-right: var(--chakra-space-6);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-small;
  font-family: "SF Mono Regular";
  line-height: var(--chakra-space-4);
  &.tag-success {
    color: $color-font-badge-success !important;
    background-color: $color-bg-badge-success !important;
  }
}
.badge-module-accordion-icon {
  margin-left: var(--chakra-space-2);
  font-size: var(--chakra-fontSizes-4xl);
}
.select-element {
  margin: var(--chakra-space-4) var(--chakra-space-6);
}
